<template>
	<h1>Home dashboard</h1>
	<h2>Welcome {{ resource.name }}!</h2>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import { mapGetters } from "vuex";

export default {
	mixins: [crudable],

	created() {
		if (this.teamId) {
			this.resourceId = this.teamId;
			this.setResourceEndpoint("teams");
			this.fetchOne();
		}
	},

	components: {},

	props: ["teamId"],

	data() {
		return {};
	},

	methods: {},

	watch: {},
	mounted() {},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
